import {useEffect, useState} from 'react';
import {API_NO_AUTHORIZATION} from '../../API';
import {Turnstile} from '@marsidev/react-turnstile';
import {useSelector} from 'react-redux';
import {useRouter} from 'next/router';
import Head from 'next/head';
import Loader from '../../components/Loader';
import {deleteCookie, getCookie, setCookie} from 'cookies-next';
import {I18nText} from '../../i18n/I18nText';
import {useI18NStringProvider} from '../../i18n/i18n';

export default function LoginIndex() {
    const router = useRouter();
    const [message, setMessage] = useState(<Loader timeout={10000}/>); // We need to account for Turnstile taking a few secs
    const [turnstileToken, setTurnstileToken] = useState();
    const previousPage = useSelector((state) => state.previousPage);
    const i18n = useI18NStringProvider();

    useEffect(() => {
        async function login() {
            const {auth, code, fido2, otp, consent_reactivation: consent} = router.query;

            if (!turnstileToken) {
                return;
            }

            if (!auth && !code) {
                setCookie('last_page', previousPage, {maxAge: 0});
                let state = `${window.location.hostname === 'localhost' ? 'local-' : ''}${router.locale}`;
                return window.location = `https://discord.com/oauth2/authorize?client_id=1111987560344985660&redirect_uri=https%3A%2F%2Fauthentication-redirection-gateway.cornhub.website&state=${state}&response_type=code&scope=identify+role_connections.write&prompt=none`;
            }

            try {
                const response = await API_NO_AUTHORIZATION.post('/auth', {
                    auth,
                    code,
                    fido2,
                    otp,
                    consent_reactivation: consent,
                    turnstile: turnstileToken
                });

                if (response.errored) {
                    const {data} = response.response;
                    if (data.requested_deletion_at) {
                        router.push(`/login/consentreactivation?auth=${data.auth}&requested_at=${data.requested_deletion_at}`);
                        return;
                    }
                    if (data.supported2FA) {
                        router.push(`/login/2fa?auth=${data.auth}&supported_methods=${data.supported2FA.join('+')}`);
                        return;
                    }

                    setMessage(`Failed to validate token. Please try again later.${data && data.error ? ` Error: ${data.error}` : ''}`);
                    return;
                }

                setCookie('authentication', response.data.token, {maxAge: Math.floor(response.data.expires_in / 1000)});
                let lastPage = getCookie('last_page');
                deleteCookie('last_page');
                window.location = lastPage ?? '/';
            } catch (e) {
                setMessage('Failed to validate token. Please try again later.');
            }
        }

        login();
    }, [router, turnstileToken]);

    return <div className="text-center m-auto w-full p-3">
        <Head>
            <title>{`${i18n('login.html_title')} - CornHub`}</title>
        </Head>

        {turnstileToken === null ?
            <p className="text-red-500"><I18nText id="turnstile.verification_failed"/></p> : null}
        {turnstileToken === undefined ?
            <p className="text-blue-500"><I18nText id="turnstile.verifying"/></p> : null}

        {typeof message !== 'string' ? message : <p>{message}</p>}

        <Turnstile siteKey="0x4AAAAAAABzV2tD73ijh26J" onSuccess={(token) => setTurnstileToken(token)}
                   onError={() => setTurnstileToken(null)} options={{action: 'login', size: 'invisible'}}/>
    </div>;
}
